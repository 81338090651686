/*
 * @Author: shikai
 * @Date: 2020-10-23 16:36:00
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-18 18:36:15
 */
import Vue from "vue";
import Vuex from "vuex";
import { AppState } from "./modules/app";
import { UserState } from "./modules/user";

Vue.use(Vuex);

export interface RootState {
	app: AppState;
	user: UserState;
}

// Declare empty store first, dynamically register all modules later.
export default new Vuex.Store<RootState>({});
