/*
 * @Author: shikai
 * @Date: 2020-11-18 18:25:04
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-18 18:27:30
 */
import Cookies from "js-cookie";

const TokenKey = "YC_SAAS_TOKEN";

export const getToken = () => Cookies.get(TokenKey);

export const setToken = (token: string) => Cookies.set(TokenKey, token);

export const removeToken = () => Cookies.remove(TokenKey);
