/*
 * @Author: shikai
 * @Date: 2020-11-20 10:58:50
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-20 10:59:26
 */
/**
 * 添加条目
 * @param key
 * @param val
 */
function setItem(key: string, val: any) {
	window.sessionStorage.setItem(key, val);
}

/**
 * 读取条目
 * @param key
 */
function getItem(key: string) {
	return window.sessionStorage.getItem(key);
}

/**
 * 清空应用储存
 */
function clear() {
	window.sessionStorage.clear();
}

export default {
	setItem,
	getItem,
	clear
};
