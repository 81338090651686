/*
 * @Author: shikai
 * @Date: 2020-10-23 16:36:00
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-05 16:39:33
 */
import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";

import "./registerServiceWorker";
import "./assets/css/common.css";

Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
