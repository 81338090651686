/*
 * @Author: shikai
 * @Date: 2020-10-23 16:36:00
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-19 15:50:52
 */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Common from "../components/Common.vue";
import Apply from "../views/apply/index.vue";
import User from "../views/user/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "",
		component: Common,
		redirect: "/",
		children: [
			{
				path: "/",
				name: "Index",
				meta: { homePages: true },
				component: () => import("@/views/Home.vue")
			},
			{
				path: "/login",
				name: "Login",
				meta: { homePages: true },
				component: () => import("@/views/Login.vue")
			},
			{
				path: "/register",
				name: "Register",
				meta: { homePages: true },
				component: () => import("@/views/Register.vue")
			},
			{
				path: "/userInfo",
				name: "UserInfo",
				component: () => import("@/views/UserInfo.vue")
			},
			{
				path: "/findPassword",
				name: "FindPassword",
				meta: { homePages: true },
				component: () => import("@/views/FindPassword.vue")
			},
			{
				// 申请接入
				path: "/applyAccess",
				name: "ApplyAccess",
				component: () => import("@/views/ApplyAccess.vue")
			},
			{
				path: "/apply",
				name: "Apply",
				component: Apply,
				children: [
					{
						path: "access",
						name: "Access",
						meta: { homePages: true },
						component: () => import("@/views/apply/Access.vue")
					},
					{
						path: "solution",
						name: "Solution",
						meta: { homePages: true },
						component: () => import("@/views/apply/Solution.vue")
					},
					{
						path: "product",
						name: "Product",
						meta: { homePages: true },
						component: () => import("@/views/apply/Product.vue")
					},
					{
						// 行业赋能
						path: "industry",
						name: "Industry",
						meta: { homePages: true },
						component: () => import("@/views/apply/Industry.vue")
					},
					{
						path: "partner",
						name: "Partner",
						meta: { homePages: true },
						component: () => import("@/views/apply/Partner.vue")
					}
				]
			},
			{
				path: "/user",
				name: "User",
				component: User,
				children: [
					{
						//基础资料
						path: "info",
						name: "Info",
						component: () => import("@/views/user/Info.vue")
					},
					{
						//我的工具
						path: "myTool",
						name: "MyTool",
						component: () => import("@/views/user/MyTool.vue")
					},
					{
						//我的订阅
						path: "mySubscribe",
						name: "MySubscribe",
						component: () => import("@/views/user/MySubscribe.vue")
					},
					{
						//我的硬件
						path: "myHardware",
						name: "MyHardware",
						component: () => import("@/views/user/MyHardware.vue")
					},
					{
						//消息通知
						path: "news",
						name: "News",
						component: () => import("@/views/user/News.vue")
					}
				]
			}
		]
	}
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
});

export default router;
