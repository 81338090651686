















import { Component, Prop, Vue } from "vue-property-decorator";
import Headers from "./Headers.vue";
import Footers from "./Footers.vue";

@Component({
	components: {
		Headers,
		Footers
	}
})
export default class HelloWorld extends Vue {
	@Prop() private msg!: string;
}
