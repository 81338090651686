/*
 * @Author: shikai
 * @Date: 2020-11-04 15:31:48
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-05 15:16:13
 */
import {
	VuexModule,
	Module,
	Mutation,
	Action,
	getModule
} from "vuex-module-decorators";
import store from "@/store";

export interface AppState {
	activeNav: string;
}

@Module({ dynamic: true, store, name: "app" })
class App extends VuexModule implements AppState {
	public activeNav = "";

	@Action({ commit: "ACTIVE_NAME" })
	public setActiveNac(activeNav: string) {
		return activeNav;
	}

	@Mutation
	private ACTIVE_NAME(activeNav: string) {
		this.activeNav = activeNav;
	}
}

export const AppModule = getModule(App);
