/*
 * @Author: shikai
 * @Date: 2020-10-30 12:01:23
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-20 10:14:30
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import request from "../utils/request";

// 注册
export const getRegister = (data: object) =>
	request({
		url: "/v1/user",
		method: "post",
		data
	});

// 检查手机号是否存在
export const mobileChecker = (mobile: string) =>
	request({
		url: "/v1/user/mobile/checker",
		method: "post",
		data: {
			mobile
		}
	});

// 发送短信验证码
export const smsRegister = (data: object) =>
	request({
		url: "/v1/sms/register",
		method: "post",
		data
	});

// 用户登录
export const userLogin = (mobile: string, password: string) =>
	request({
		url: "/v1/user/ticket",
		method: "post",
		data: {
			mobile,
			password
		}
	});

//  获取用户信息(token)
export const userInfo = (token: string) =>
	request({
		url: "/v1/user",
		method: "get",
		params: { token }
	});

//  获取公司分类
export const companyType = () =>
	request({
		url: "/v1/user/company/type/list",
		method: "get"
	});

//  完善公司信息
export const companyInfo = (data: object) =>
	request({
		url: "/v1/user/company",
		method: "post",
		data
	});
