


































































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
@Component({
	name: "Header"
})
export default class Header extends Vue {
	private navList = [
		{
			name: "首页",
			url: "/"
		},
		{
			name: "免费工具",
			url: "/apply/solution"
		},
		{
			name: "服务订阅",
			url: "/apply/access"
		},
		{
			name: "硬件超市",
			url: "/apply/product"
		},
		{
			name: "能力开放",
			url: "/apply/industry"
		},
		{
			name: "客户展示",
			url: "/apply/partner"
		}
	];

	get activeNav() {
		return AppModule.activeNav;
	}

	get userInfo() {
		return UserModule;
	}

	get isShowTop() {
		const urlList: Array<string> = ["/login", "/register", "/findPassword"];
		if (urlList.indexOf(this.$route.path) !== -1) {
			return false;
		} else {
			return true;
		}
	}

	private goTo(path: string) {
		this.$router.push({
			path
		});
	}

	private setActiveName(name: string) {
		AppModule.setActiveNac(name);
	}

	private exitSaas() {
		console.log(123);
	}
}
