




















































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Apply extends Vue {
	private parentData = {};
	private goTo(path: string) {
		this.$router.push({
			path
		});
	}

	private childrenData(val: {}) {
		this.parentData = val;
	}
}
