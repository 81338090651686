/*
 * @Author: shikai
 * @Date: 2020-10-30 15:16:50
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-20 10:34:45
 */
import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { getToken } from "@/utils/auth";
import { UserModule } from "@/store/modules/user";

const service = axios.create({
	// baseURL: process.env.BASE_URL,
	// baseURL: "http://47.93.113.119:19000",
	timeout: 5000
});

// Request interceptors
service.interceptors.request.use(
	config => {
		// if (UserModule.token) {
		config.headers["Authorization"] = getToken();
		// }
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// Response interceptors
service.interceptors.response.use(
	response => {
		// Some example codes here:
		// code == 20000: valid
		// code == 50008: invalid token
		// code == 50012: already login in other place
		// code == 50014: token expired
		// code == 60204: account or password is incorrect
		// You can change this part for your own usage.
		const res = response.data;
		console.log(res);
		if (res.code !== 0) {
			if (
				res.code === 50008 ||
				res.code === 50012 ||
				res.code === 50014
			) {
				MessageBox.confirm(
					"你已被登出，可以取消继续留在该页面，或者重新登录",
					"确定登出",
					{
						confirmButtonText: "重新登录",
						cancelButtonText: "取消",
						type: "warning"
					}
				).then(() => {
					// UserModule.FedLogOut().then(() => {
					// 	location.reload(); // To prevent bugs from vue-router
					// });
				});
			} else {
				Message({
					message: res.message,
					type: "error",
					duration: 5 * 1000
				});
				return res;
			}
		} else {
			return res;
		}
	},
	error => {
		// Message({
		// 	message: error.message,
		// 	type: "error",
		// 	duration: 5 * 1000
		// });
		return Promise.reject(error);
	}
);

export default service;
