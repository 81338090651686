













import { Component, Vue } from "vue-property-decorator";

@Component({
	name: "Footer"
})
export default class Footer extends Vue {}
