




































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";

@Component({
	filters: {
		encryptionPhone(phone: string) {
			const start = phone.substring(0, 3);
			const end = phone.substring(7, 11);
			return `${start}****${end}`;
		}
	}
})
export default class User extends Vue {
	private userInfo = {};

	private navList = [
		{
			name: "基础资料",
			url: "/user/info"
		},
		{
			name: "我的工具",
			url: "/user/myTool"
		},
		{
			name: "我的订阅",
			url: "/user/mySubscribe"
		},
		{
			name: "我的硬件",
			url: "/user/myHardware"
		},
		{
			name: "消息通知",
			url: "/user/news"
		}
	];

	get activeNav() {
		return AppModule.activeNav;
	}

	get userInfos() {
		return UserModule;
	}

	// 设置active状态
	private setActiveName(name: string) {
		AppModule.setActiveNac(name);
	}

	private goTo(path: string) {
		this.$router.push({
			path
		});
	}
}
