/*
 * @Author: shikai
 * @Date: 2020-11-18 18:30:25
 * @LastEditors: shikai
 * @LastEditTime: 2020-11-20 15:23:20
 */
import {
	VuexModule,
	Module,
	MutationAction,
	Mutation,
	Action,
	getModule
} from "vuex-module-decorators";
import { userLogin, userInfo } from "@/api/login";
// import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from "@/utils/auth";
import localStore from "@/utils/local_store";
import store from "@/store";

export interface UserState {
	token: string;
	username: string;
	real_name: string;
	mobile: string;
	has_company: boolean;
}

@Module({ dynamic: true, store, name: "user" })
class User extends VuexModule implements UserState {
	public token = "";
	public username = "";
	public real_name = "";
	public mobile = "";
	public has_company = false;

	@Action({ commit: "SET_TOKEN" })
	public async Login(userInfo: { mobile: string; password: string }) {
		const mobile = userInfo.mobile.trim();
		const data = await userLogin(mobile, userInfo.password);
		console.log(data);
		if (data.data.token) {
			console.log(123);
			setToken(data.data.token);
			this.GetInfo();
			return data;
		} else {
			return data;
		}
	}

	@Action({ commit: "SET_TOKEN" })
	public async FedLogOut() {
		removeToken();
		return "";
	}

	@MutationAction({
		mutate: ["username", "real_name", "mobile", "has_company"]
	})
	public async GetInfo() {
		const token = getToken();
		if (token === undefined) {
			throw Error("GetInfo: token is undefined!");
		}
		const { data } = await userInfo(token);
		if (data.username) {
			localStore.setItem("USER_INF", JSON.stringify(data));
			return {
				username: data.username,
				// eslint-disable-next-line @typescript-eslint/camelcase
				real_name: data.real_name,
				mobile: data.mobile,
				// eslint-disable-next-line @typescript-eslint/camelcase
				has_company: data.has_company
			};
		} else {
			throw Error("GetInfo: roles must be a non-null array!");
		}
	}

	@MutationAction({ mutate: ["token"] })
	public async LogOut() {
		if (getToken() === undefined) {
			throw Error("LogOut: token is undefined!");
		}
		// await logout();
		removeToken();
		return {
			token: ""
		};
	}

	@Mutation
	private SET_TOKEN(token: string) {
		this.token = token;
	}
}

export const UserModule = getModule(User);
